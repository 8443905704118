/*
 * Copyright Schrodinger, LLC
 */

import environment from 'bb/util/environment';

/**
 * NOTE (coman) gotta do this for exports to work
 * @const
 * @export
 */
const bbConsts = {};

/**
 * @type {string}
 * @const
 */
bbConsts.SITE_ROOT = goog.global.location.protocol + '//' + goog.global.location.host;

/**
 * Root for all API requests to the LiveDesign REST API.
 *
 * @type {string}
 * @const
 */
bbConsts.WEBSERVICE_ROOT = bbConsts.SITE_ROOT + '/livedesign/api/';

/**
 * Link for the help documentation.
 *
 * @type {string}
 * @const
 */
bbConsts.HELP_LINK = bbConsts.SITE_ROOT + '${helpLink}';

/**
 * Link for the help documentation at /tutorial endpoint.
 *
 * @type {string}
 * @const
 */
bbConsts.HELP_LINK_TUTORIAL_ENDPOINT = bbConsts.SITE_ROOT + '/livedesign/tutorial';

/**
 * Link for the EULA
 *
 * @type {string}
 * @const
 */
bbConsts.EULA_LINK = 'http://www.schrodinger.com/Legal/eula.html';

/**
 * Link for the copyright notices
 *
 * @type {string}
 * @const
 */
bbConsts.COPYRIGHT_LINK =
  bbConsts.SITE_ROOT + '/livedesign/static/resources/licenses/version_license.txt';

/**
 * Link for the help documentation.
 *
 * @type {string}
 * @const
 */
bbConsts.HELP_PATH_DRUG_DISC = '/livedesign/documentation';

/**
 * Link for the help documentation.
 *
 * @type {string}
 * @const
 */
bbConsts.HELP_PATH_MATSCI = '/livedesign/documentation-matsci';

/**
 * Prefix for admin link.
 *
 * @type {string}
 * @const
 */
bbConsts.ADMIN_LINK_PREFIX = '/admin';

/**
 * Link to the admin panel.
 *
 * @type {string}
 * @const
 */
bbConsts.ADMIN_LINK = bbConsts.SITE_ROOT + bbConsts.ADMIN_LINK_PREFIX;

/**
 * Root for all API requests to LD Admin API.
 *
 * @type {string}
 * @const
 */
bbConsts.ADMIN_ROOT = bbConsts.ADMIN_LINK + '/api';

/**
 * URL prefix for authorization service.
 *
 * @type {string}
 * @const
 */
bbConsts.AUTHN_SERVICE_PREFIX = '/authn';

/**
 * URL link to the authorization service.
 *
 * @type {string}
 * @const
 */
bbConsts.AUTHN_SERVICE_ENDPOINT = bbConsts.SITE_ROOT + bbConsts.AUTHN_SERVICE_PREFIX;

/**
 * URL link to the authorization service login endpoint.
 *
 * @type {string}
 * @const
 */
bbConsts.AUTHN_SERVICE_LOGIN_ENDPOINT = bbConsts.AUTHN_SERVICE_ENDPOINT + '/login';

/**
 * URL link to the authorization service login endpoint.
 *
 * @type {string}
 * @const
 */
bbConsts.AUTHN_SERVICE_REFRESH_ENDPOINT = bbConsts.AUTHN_SERVICE_ENDPOINT + '/refresh';

/**
 * Task Engine Task link
 *
 * @type {string}
 * @const
 */
bbConsts.TE_TASK_LINK = bbConsts.ADMIN_LINK + '/tetasks/task/${taskEngineId}/change';

/**
 * Link to reset password.
 *
 * @type {string}
 * @const
 */
bbConsts.RESET_PASSWORD_LINK = bbConsts.SITE_ROOT + '/pwm/public/ForgottenPassword';

/**
 * Link to the admin panel.
 *
 * @type {string}
 * @const
 */
bbConsts.COVERPAGE_URL = bbConsts.ADMIN_LINK + '/coverpage/${projectId}';

/**
 * The number of minutes to wait before logging out an idle user.
 *
 * @type {number}
 * @const
 */
bbConsts.LOGOUT_IDLE_USER_TIMEOUT = 1440; //24hrs

/**
 * The default lot number for registering ideas compounds.
 * @type {number}
 * @const
 */
bbConsts.IDEAS_LOT_NUMBER = 1;

/**
 * For dev and prod we serve index at /livedesign/ and content at /livedesign/static/
 * For tests we serve the test at /livedesign/static/tests/ and content at /livedesign/static/
 *
 * @type {string}
 * @const
 */
bbConsts.STATIC_PREFIX = __TEST__ ? '../' : 'static/';

/**
 * Path used for images in our CSS or img tag elements
 *
 * @type {string}
 * @const
 */
bbConsts.IMAGES_RELPATH = bbConsts.STATIC_PREFIX + 'resources/images/';

/**
 * Relative path to the icons folder
 *
 * @type {string}
 * @const
 */
bbConsts.ICONS_RELPATH = bbConsts.IMAGES_RELPATH + 'icons/';

/**
 * Relative path to the icons folder for MarvinJS toolbar icons
 *
 * @type {string}
 * @const
 */
bbConsts.MARVIN_ICONS_RELPATH = 'static/resources/images/icons/';

/**
 * Minimum width for a column.
 * This is mainly in place to the column width cannot be set to 0, and seemingly "disappear".
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_MINIMUM_COLUMN_WIDTH = 70;

/**
 * The width of the border on grid cells.
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_CELL_BORDER_WIDTH = 2;

/**
 * Default width for a molecule column
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_DEFAULT_MOLECULE_COLUMN_WIDTH = 210;

/**
 * Default width for a column.
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_DEFAULT_COLUMN_WIDTH = 102;

/**
 * Keep in sync with the height on class ScrollbarLayout_mainHorizontal in Fixed Data Table.
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_BOTTOM_SCROLLBAR_HEIGHT = 15;

/**
 * Height for the headers in the "Import Compound" accordion panels
 *
 * @type {number}
 * @const
 */
bbConsts.IMPORT_COMPOUND_ACCORDION_HEIGHT = 37;

/**
 * The key identifier for the key field in a grid row
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_ID_IDENTIFIER = 'Concatenated ID';

/**
 * The key identifier for the Corporate ID field
 * TODO (tchoi) this has been changed to be the Entity ID field.
 * HOWEVER, I have not changed the name because I don't want to break everything
 * Once we are ready, we should rename all references to Corporate ID.
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_CORPORATE_ID_IDENTIFIER = 'Entity ID';

/**
 * The key identifier for the Entity Type column
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_ENTITY_TYPE_IDENTIFIER = 'Entity Type';

/**
 * The OLD key identifier for the Corporate ID field
 * Probably will need for cleanup going-forward.
 * NOTE (tchoi) Adding this for easier reference
 *
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_CORPORATE_ID_IDENTIFIER_OLD = 'Corporate ID';

/**
 * The key identifier for the Display Name field
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_DISPLAY_NAME_IDENTIFIER = 'ID';

/**
 * The key identifier for the All IDs field
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_ALL_IDS_IDENTIFIER = 'All IDs';

/**
 * The key identifier for the Pose ID field
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_POSE_ID_IDENTIFIER = 'Pose ID';

/**
 * @type {string}
 * @const
 */
bbConsts.LOT_IDS = 'lot_ids';

/**
 * @type {string}
 * @const
 */
bbConsts.SVG_EXTENSION = 'svg';

/**
 * @type {string}
 * @const
 */
bbConsts.SALT_IDS = 'salt_ids';

/**
 * @type {string}
 * @const
 */
bbConsts.STRUCTURE_HASH = 'structure_hash';

/**
 * @type {string}
 * @const
 */
bbConsts.EXPERIMENT_ID = 'experiment_id';

/**
 * The key used by BE in LRResult to denote if row is manually linked to any virtual entity.
 * TODO: Move all row_info fields to an enum.
 *
 * @type {string}
 * @const
 */
bbConsts.EXPLICITLY_LINKED = 'explicitly_linked';

/**
 * @type {string}
 * @const
 */
bbConsts.ENTITY_TYPE = 'entity_type';

/**
 * @type {string}
 * @const
 */
bbConsts.GENERIC_ENTITY_FILE_ACTION_TYPE = 'generic_entity_file_action_type';

/**
 * @type {string}
 * @const
 */
bbConsts.MANAGEABLE_FILE_ID = 'manageable_file_id';

/**
 * The key identifier for the field that is the first component of the concatenated id for a row.
 *
 * @type {string}
 * @const
 */
bbConsts.GRID_ROW_ID_IDENTIFIER_PREFIX = bbConsts.GRID_ROW_CORPORATE_ID_IDENTIFIER;

/**
 * The key identifier for the structure id in a row
 * Rather than testing against this string, use LiveReportColumnType.COMPOUND where possible
 * (protects against Reactant Structure being treated differently--see SS-23533).
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_COMPOUND_IDENTIFIER = 'Compound Structure';

/**
 * The key identifier for the device recipe in a row in a DEVICE type LiveReport.
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_DEVICE_RECIPE_COLUMN_NAME = 'Device Recipe';

/**
 * The key identifier for the comment count for the row
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.GRID_ROW_COMMENT_COUNT = 'CommentCount';

/**
 * The field name used to grab the record data for rationales
 *
 * @const
 * @type {string}
 * @export
 */
bbConsts.RATIONALE_COLUMN_NAME = 'Rationale';

/**
 * We use this key for storing login warning messages if any in the sessionStorage
 *
 * @const
 * @type {string}
 * @export
 */
bbConsts.LOGIN_WARNING = 'loginWarning';

/**
 * The field name used to grab the record data for Lot Scientist
 *
 * @const
 * @type {string}
 * @export
 */
bbConsts.LOT_SCIENTIST_COLUMN_NAME = 'Lot Scientist';

/**
 * The field name used to grab the record data for Lot Date Registered
 *
 * @const
 * @type {string}
 * @export
 */
bbConsts.LOT_DATE_REGISTERED_COLUMN_NAME = 'Lot Date Registered';

/**
 * The column name for Scaffold IDs
 *
 * @const
 * @type {string}
 * @export
 */
bbConsts.SCAFFOLD_ID_COLUMN_NAME = 'Matched Scaffold';

/**
 * The column name for the "Scaffold Name" column
 *
 * @const
 * @type {string}
 * @export
 */
bbConsts.SCAFFOLD_NAME_COLUMN_NAME = 'Scaffold Name';

/**
 * Columns to exclude from filter list.
 *
 * @type {!Array<string>}
 * @const
 */
bbConsts.INVALID_FILTER_COLUMNS = [
  bbConsts.GRID_ROW_CORPORATE_ID_IDENTIFIER_OLD,
  bbConsts.RATIONALE_COLUMN_NAME,
];

/**
 * @type {string}
 * @const
 */
bbConsts.PLDB_FILE_ACTION_TYPE = 'PLDB';

/**
 * @type {string}
 * @const
 */
bbConsts.THREE_D_FILE_ACTION_TYPE = 'THREE_D';

/**
 * The key used by BE in LRResult to denote cell bg_color
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_BG_COLORS = 'bg_colors';

/**
 * The key used by BE in LRResult to denote cell's bg_colors wrt each mpo the column is a part of
 *
 * @type {string}
 * @const
 */
bbConsts.MPO_COLORS = 'mpo_colors_map';

/**
 * The key used by BE in LRResult to denote if cell's value should be underlined
 *
 * @type {string}
 * @const
 */
bbConsts.SHOW_UNDERLINE = 'show_underline';

/**
 * The key used by BE in LRResult to denote cell operator
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_OPERATORS = 'operators';

/**
 * The key used by BE in LRResult to denote cell status
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_STATUSES = 'statuses';

/**
 * The key used by BE in LRResult to denote cell tooltip
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_TOOLTIPS = 'tooltips';

/**
 * Hash of value in a cell.
 * Currently just used to tell if/when a compound image changed
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_HASH = 'hash';

/**
 * The key used by BE in LRResult to denote cell status code
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_STATUS_CODE = 'code';

/**
 * The key used by BE in LRResult to denote cell status message, i.e., the TE id
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_STATUS_MESSAGE = 'message';

/**
 * The key used by BE in LRResult to denote entityId
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_ENTITY_ID = 'entity_id';

/**
 * The key used by BE in LRResult to denote Global EntityId
 *
 * @type {string}
 * @const
 */
bbConsts.GLOBAL_ENTITY_ID = 'global_entity_id';

/**
 * The key used by BE in LRResult to denote virtualEntityId
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_VIRTUAL_ENTITY_ID = 'virtual_entity_id';

/**
 * The key used by BE in LRResult to denote poseId
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_POSE_ID = 'pose_id';

/**
 * The key used by BE in LRResult to denote if row is frozen
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_FROZEN = 'frozen';

/**
 * The key used by BE in LRResult to denote the relationships associated with this row.
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_ENTITY_RELATIONSHIPS = 'entity_relationship_metadata';

/**
 * TODO: find better place
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_ENTITY_RELATIONSHIP_FORMULATION = 'entity_formulation';

/**
 * The key used by BE in LRResult to denote the parent row key.
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_PARENT_ROW_KEY = 'parent_row_key';

/**
 * The key used by BE in LRResult to denote a row's cells object
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_CELLS = 'cells';

/**
 * The key used by BE in LRResult to denote a cell's values array
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_VALUES = 'values';

/**
 * The key used by BE in LRResult to denote a cell's style object
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_STYLE = 'style';

/**
 * The key identifier for a value in a LiveReport's Cell
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_VALUE = 'value';

/**
 * The key identifier for a metadata in a LiveReport's Cell
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_METADATA = 'metadata';

/**
 * The parameter to request expanded poses
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_POSES_PARAM = 'pose';

/**
 * The parameter to define report level
 *
 * @type {string}
 * @const
 */
bbConsts.REPORT_LEVEL = 'report_level';

/**
 * The parameter to define experiment id
 *
 * @type {string}
 * @const
 */
bbConsts.EXPERIMENT_ADDABLE_COLUMN_ID = 'experiment_addable_column_id';

/**
 * The parameter to request frozen rows
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_FROZEN_PARAM = 'frozen';

/**
 * The parameter to request column projection
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_PROJECTION_PARAM = 'projection';

/**
 * The parameter to request start index paged result
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_START_PARAM = 'start';

/**
 * The parameter to result size of paged result
 *
 * @type {string}
 * @const
 */
bbConsts.RESULT_SIZE_PARAM = 'size';

/**
 * The grid row id for the sketched compound.
 *
 * @type {string}
 * @const
 */
bbConsts.SKETCHED_COMPOUND_ROW_ID = 'Sketched Compound';

/**
 * The parameter used to disable cache
 * @const
 * @type {string}
 */
bbConsts.DISABLE_CACHE_PARAMETER = '_dc';

/**
 * List of contrasting colors used to create the color picker in the React color rules dialog
 * see Paul or product if you want to change them.
 *
 * @type {!Array<string>}
 * @const
 */
bbConsts.DISTINCT_COLORS = [
  '#FFFFFF',
  '#FFCCCC',
  '#FFCC99',
  '#FFFF99',
  '#FFFFCC',
  '#99FF99',
  '#99FFFF',
  '#CCFFFF',
  '#CCCCFF',
  '#FFCCFF',
  '#CCCCCC',
  '#FF6666',
  '#FF9966',
  '#FFFF66',
  '#FFFF33',
  '#66FF99',
  '#33FFFF',
  '#66FFFF',
  '#9999FF',
  '#FF99FF',
  '#C0C0C0',
  '#FF0000',
  '#FF9900',
  '#FFCC66',
  '#FFFF00',
  '#33FF33',
  '#66CCCC',
  '#33CCFF',
  '#6666CC',
  '#CC66CC',
  '#999999',
  '#CC0000',
  '#FF6600',
  '#FFCC33',
  '#FFCC00',
  '#33CC00',
  '#00CCCC',
  '#3366FF',
  '#6633FF',
  '#CC33CC',
  '#666666',
  '#990000',
  '#CC6600',
  '#CC9933',
  '#999900',
  '#009900',
  '#339999',
  '#3333FF',
  '#6600CC',
  '#993399',
  '#333333',
  '#660000',
  '#993300',
  '#996600',
  '#666600',
  '#006600',
  '#336666',
  '#000099',
  '#333399',
  '#663366',
  '#000000',
  '#330000',
  '#663300',
  '#664400',
  '#333300',
  '#003300',
  '#003333',
  '#000066',
  '#330099',
  '#330033',
];

/**
 * The default color index of DISTINCT_COLORS.
 *
 * @const
 * @type {!number}
 */
bbConsts.DISTINCT_COLORS_DEFAULT_COLOR_INDEX = 21; // FF0000

bbConsts.WHITE = '#FFFFFF';
bbConsts.GRAY = '#535353';
bbConsts.LIGHT_GRAY = '#D3D3D3';

bbConsts.DEFAULT_COLOR_LOW = bbConsts.LIGHT_GRAY;
bbConsts.DEFAULT_COLOR_HIGH = '#FF0000';

/**
 * The default value for maximum number of enumeration results.
 *
 * @const
 * @type {number}
 */
bbConsts.DEFAULT_MAX_ENUMERATION_RESULTS = 1000;

/**
 * @type {string}
 * @const
 * @private
 */
bbConsts.BBCHEM_RELATIVE_PATH = '/ld-chem';

/**
 * @type {string}
 * @const
 */
bbConsts.BBCHEM_ROOT =
  goog.global.location.protocol + '//' + goog.global.location.host + bbConsts.BBCHEM_RELATIVE_PATH;

/**
 * @const
 * @type {string}
 */
bbConsts.PLDB_ROOT = goog.global.location.protocol + '//' + goog.global.location.host + '/pldb';

/**
 * The prefix for tag ID's in the LiveReport tag tree.
 *
 * @const
 * @type {string}
 */
bbConsts.TAG_TREE_PREFIX = 'TAG';

/**
 * The id for the Private LiveReports tag for the folder view.
 *
 * @const
 * @type {string}
 */
bbConsts.PRIVATE_LIVEREPORTS = 'PrivateLRs';

/**
 * The id for the Shared Projects tag for the folder view.
 *
 * @const
 * @type {string}
 */
bbConsts.SHARED_PROJECTS = 'SharedProjects';

/**
 * Logging Level for log records forwarded to backend webservice.
 */
bbConsts.LOG_FORWARDING_LEVEL = 'ALL';

/**
 * Width for the marvin sketcher
 *
 * @type {number}
 * @const
 */
bbConsts.MARVIN_SKETCHER_WIDTH = 515;

/**
 * This sets how much of the enumeration window is allowed to be offscreen.
 *
 * @type {number}
 * @const
 */
bbConsts.ENUMERATION_WINDOW_BUFFER = 200;

/**
 * Token used in filters and queries to represent all defined values
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.DEFINED_TOKEN = '(defined)';

/**
 * Token used in filters and queries to represent all undefined values
 *
 * @type {string}
 * @const
 * @export
 */
bbConsts.UNDEFINED_TOKEN = '(undefined)';

/**
 * @type {number}
 * @const
 */
bbConsts.DEFAULT_MORGAN_FP_SCORE = 0.35;

/**
 * Quote characters used to tokenize string-matching color rules.
 *
 * @type {string}
 * @const
 */
bbConsts.COLOR_RULE_QUOTE_CHARS = '"';

/**
 * Delimiter characters used to tokenise string-matching color rules and
 * NOTE(novaak)  must be kept in synch with BE
 * com.synsci.compchem.colors.cellcolorstyle.CellColorStyle.FIELD_DELIMITERS.
 *
 * @type {string}
 * @const
 */
bbConsts.COLOR_RULE_DELIMITER_CHARS = '|;,\t ';

/**
 * We have to use some arbitrary delimiter because we are allowing comma
 * We are assuming (and hoping) that no one will use __DEBUG__ as a value
 *
 * @type {string}
 * @const
 */
bbConsts.DEBUG_DELIMITER = '__DEBUG__';

/**
 * @type {string}
 * @const
 */
bbConsts.X_RESULT_PROMISE_ID = 'X-Result-Promise-ID';

/**
 * @type {string}
 * @const
 */
bbConsts.X_LIVEDESIGN_NOTIFICATION = 'X-LiveDesign-Notification';

/**
 * @type {string}
 * @const
 */
bbConsts.COMMA_DELIMITER = ',';

/**
 * @type {string}
 * @const
 */
bbConsts.NEWLINE_DELIMITER = '\n';

/**
 * @type {string}
 * @const
 */
bbConsts.TAB_DELIMITER = '\t';

/**
 * @type {string}
 * @const
 */
bbConsts.COLUMN_TREE_ROOT_ID = 'root';

/**
 * @type {string}
 * @const
 */
bbConsts.STATS = 'stats';

/**
 * The width of the action pane with no opened drawers
 * @type {number}
 */
bbConsts.ACTION_PANE_WIDTH = 50;

/**
 * The default width for an action pane drawer (drawer only, not including the pane)
 *
 * @type {number}
 */
bbConsts.ACTION_PANE_DRAWER_WIDTH = 250;

/**
 * The width for add compound drawer using Maestro
 *
 * @type {number}
 */
bbConsts.ACTION_PANE_ADD_COMPOUND_DRAWER_WIDTH = 525;

/**
 * The starting width for Advanced Query pane
 *
 * @type {number}
 */
bbConsts.ACTION_PANE_ADV_QUERY_DRAWER_WIDTH = 385;

/**
 * The width for the Enumerate pane
 *
 * @type {number}
 */
bbConsts.ACTION_PANE_ENUMERATE_DRAWER_WIDTH = 385;

/**
 * The width for the Import pane
 *
 * @type {number}
 */
bbConsts.ACTION_PANE_IMPORT_DRAWER_WIDTH = 385;

/**
 * The width for larger action pane drawers
 *
 * @type {number}
 */
bbConsts.ACTION_PANE_LARGE_DRAWER_WIDTH = 650;

/**
 * The width for larger action pane drawers
 *
 * @type {number}
 */
bbConsts.ACTION_PANE_COMPOUNDS_WIDTH = 450;

/**
 * The height of Maestro Sketcher
 *
 * @type {number}
 */
bbConsts.MAESTRO_SKETCHER_HEIGHT = 520;

/**
 * Default number of rows per page in Grid page
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_PAGE_SIZE = 25;

/**
 * Default buffer pages to store
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_PAGE_BUFFER = 9;

/**
 * Page size * pages to keep in buffer = number of rows to give us a buffer.
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_BUFFER_ROW_COUNT = bbConsts.GRID_PAGE_SIZE * bbConsts.GRID_PAGE_BUFFER;

/**
 * Round start/end pages to this number.
 * So we keep the ranges discrete, which lowers demand on BE
 * SS-35818
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_PAGE_ROUNDING = 50;

/**
 * Round start/end pages to this number.
 * So we keep the ranges discrete, which lowers demand on BE
 * SS-35818
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_ROW_BUFFER_COUNT = bbConsts.GRID_PAGE_SIZE * bbConsts.GRID_PAGE_BUFFER;

/**
 * Default buffer for columns
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_COLUMN_BUFFER_SIZE =
  environment.asInt(environment.SettingKey.COLUMN_PAGINATION_BUFFER_SIZE) || 50;

/**
 * Round start/end pages to this number.
 * So we keep the ranges discrete, which lowers demand on BE
 * SS-35818
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_COLUMN_ROUNDING =
  environment.asInt(environment.SettingKey.COLUMN_PAGINATION_BUCKET_SIZE) || 25;

/**
 * Number of bytes in one megabyte
 *
 * @type {number}
 * @const
 */
bbConsts.BYTES_PER_MEGABYTE = 1000000;

/**
 * Number of days in one week
 *
 * @type {number}
 * @const
 */
bbConsts.DAYS_IN_A_WEEK = 7;

/**
 * Project Id for the GLOBAL project
 *
 * @type {string}
 * @const
 */
bbConsts.GLOBAL_PROJECT_ID = '0';

/**
 * Default length of notification in ms
 *
 * @type {number}
 * @const
 */
bbConsts.DEFAULT_NOTIFICATION_DURATION = 6000;

/**
 * The key used to store the active workspace's id.
 * NOTE (jordan) Should be set to something that won't collide with a workspace id.
 *
 * @type {string}
 * @const
 */
bbConsts.WORKSPACE_LOCAL_STORAGE_KEY = 'activeWorkspace';

/**
 * @type {number}
 * @const
 * @export
 */
bbConsts.MILLISECONDS_IN_A_SECOND = 1000;

/**
 * @type {number}
 * @const
 * @export
 */
bbConsts.MILLISECONDS_IN_A_MINUTE = bbConsts.MILLISECONDS_IN_A_SECOND * 60;

/**
 * @type {number}
 * @const
 * @export
 */
bbConsts.MILLISECONDS_IN_AN_HOUR = bbConsts.MILLISECONDS_IN_A_MINUTE * 60;

/**
 * @type {number}
 * @const
 * @export
 */
bbConsts.MILLISECONDS_IN_A_DAY = bbConsts.MILLISECONDS_IN_AN_HOUR * 24;

/**
 * @type {number}
 * @const
 */
bbConsts.REFRESHER_DEFAULT_PERIOD = 15 * bbConsts.MILLISECONDS_IN_A_SECOND;

/**
 * Unicode non-breaking space
 *
 * @type {string}
 * @const
 */
bbConsts.UNICODE_NBSP = '\u00a0';

/**
 * Unicode non-breaking space
 *
 * @type {string}
 * @const
 */
bbConsts.ENCODED_PERIOD = '|$|$|';

/**
 * A logger namespace for sending metrics to the backend
 *
 * @type {string}
 * @const
 */
bbConsts.SYSTEM_LOGGER = 'bbSystem';

/**
 * A namespace for sending all logs from tests to a remote server for later analysis.
 *
 * @type {string}
 * @const
 */
bbConsts.BUILD_LOGGER = 'buildLogger';

/**
 * max-width to be used in media queries for mobile devices
 *
 * @type {number}
 * @const
 */
bbConsts.MOBILE_DEVICES_MAX_WIDTH = 767;

/**
 * max-width to be used in media queries for mobile devices
 *
 * @type {number}
 * @const
 */
bbConsts.DESKTOP_DEVICES_MIN_WIDTH = bbConsts.MOBILE_DEVICES_MAX_WIDTH + 1;

/**
 * A valid image src for a transparent image (1x1px)
 *
 * @type {string}
 * @const
 */
bbConsts.TRANSPARENT_IMAGE_SRC =
  'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';

/**
 * The delay for the tree panels between when a user stops erasing the filter text and
 * when we rebuild the tree.
 *
 * @type {number}
 * @const
 */
bbConsts.INITIALIZATION_DELAY = 500;

/**
 * @type {string}
 * @const
 */
bbConsts.GADGETS_PREFIX = 'gadgets/';

/**
 * @type {string}
 * @const
 */
bbConsts.GADGETS_ROOT = bbConsts.SITE_ROOT + '/livedesign/' + bbConsts.GADGETS_PREFIX;

/**
 * @type {string}
 * @const
 */
bbConsts.PLDB_GADGET_API = bbConsts.GADGETS_ROOT + 'pldb';

/**
 * @type {string}
 * @const
 */
bbConsts.INFINITY = '\u221E';

/**
 * @type {string}
 * @const
 */
bbConsts.NEGATIVE_INFINITY = '-\u221E';

/**
 * @type {string}
 * @const
 */
bbConsts.VERTICAL_ELLIPSIS = '\u22EE';

/**
 * Height of the Grid Tabs
 *
 * @type {number}
 * @const
 */
bbConsts.GRID_TAB_HEIGHT = 23;

/**
 * Default logo used in header
 *
 * @type {string}
 * @const
 */
bbConsts.DEFAULT_LD_LOGO = bbConsts.IMAGES_RELPATH + 'logo.png';

/**
 * String to use to specify formatting especially of numbers for US English.
 *
 * @type {string}
 * @const
 */
bbConsts.UNITED_STATES_ENGLISH_FORMAT = 'en-US';

/**
 * String to use to specify formatting especially of numbers for German as spoken in Germany.
 *
 * @type {string}
 * @const
 */
bbConsts.GERMANY_GERMAN_FORMAT = 'de-DE';

/**
 * Decimal separator used in much of Europe.
 *
 * @type {string}
 * @const
 */
bbConsts.EUROPEAN_DECIMAL_SEPARATOR = ',';

/**
 * Decimal separator used in the United States.
 *
 * @type {string}
 * @const
 */
bbConsts.US_DECIMAL_SEPARATOR = '.';

/**
 * Number of decimal places to use on a number
 *
 * @type {number}
 * @const
 */
bbConsts.DEFAULT_NUMBER_PRECISION = 3;

/**
 * SS-19031, SS-19440, SS-19525
 * event.buttons uses bitwise values, instead of the usual
 * goog.events.BrowserEvent.MouseButton mapping
 */
bbConsts.BITWISE_LEFT_MOUSE_BUTTON = 1;

/*
 * Used to truncate minutes from a date, and force moment to format it in UTC
 *
 * @type {string}
 * @const
 */
bbConsts.UNIX_MIDNIGHT = 'T00:00:00Z';

/*
 * Used to signify a range that should treat the corresponding range end as inclusive.
 * Can be mixed with EXCLUSIVE_BRACKETS, ex. (10,20] means > 10 && <= 20
 *
 * @type {string}
 * @const
 */
bbConsts.INCLUSIVE_BRACKETS = '[]';

/*
 * Used to signify a range that should treat the corresponding range end as exclusive.
 * Can be mixed with INCLUSIVE_BRACKETS, ex. (10,20] means > 10 && <= 20
 *
 * @type {string}
 * @const
 */
bbConsts.EXCLUSIVE_BRACKETS = '()';

/**
 * @type {string}
 * @const
 */
bbConsts.CSV_MIME_TYPE = 'text/csv';

/**
 * Used to open a link to a new tab
 *
 * @type {string}
 * @const
 */
bbConsts.NEW_TAB = '_blank';

/**
 * Used in tests to scroll the column tree inorder to find a given element with a given text.
 *
 * @type {number}
 * @const
 */
bbConsts.COLUMN_TREE_SCROLL_LENGTH = 700;

/**
 * Used to specify the default height while rendering the smiles of the compound images.
 *
 * @type {number}
 * @const
 */
bbConsts.DEFAULT_COMPOUND_IMAGE_HEIGHT = 300;

/**
 * These are the different dimensions for which we'll request compound images
 * NOTE: The dimensions 196x147 are so that we request the image with dimensions 400x300
 * when the LR first loads with default cell dimensions of 210x150 (SS-37950)
 *
 * @type {Array<{ width:number, height:number }>}
 * @const
 */
bbConsts.COMPOUND_IMAGE_SIZE_OPTIONS = [
  {
    width: 100,
    height: 75,
  },
  {
    width: 192,
    height: 144,
  },
  {
    width: 400,
    height: 300,
  },
  {
    width: 800,
    height: 600,
  },
  {
    width: 1200,
    height: 900,
  },
];

/**
 * Used to specify the default width while rendering the smiles of the compound images.
 *
 * @type {number}
 * @const
 */
bbConsts.DEFAULT_COMPOUND_IMAGE_WIDTH = 400;

/**
 * Used to specify the height of the large sized compound images.
 *
 * @type {number}
 * @const
 */
bbConsts.LARGE_COMPOUND_IMAGE_HEIGHT = 900;

/**
 * Used to specify the width of the large sized compound images.
 *
 * @type {number}
 * @const
 */
bbConsts.LARGE_COMPOUND_IMAGE_WIDTH = 1200;

/**
 * Height of compound images of png type.
 *
 * @type {number}
 * @const
 */
bbConsts.PNG_COMPOUND_IMAGE_HEIGHT = 900;

/**
 * Width of compound images of png type.
 *
 * @type {number}
 * @const
 */
bbConsts.PNG_COMPOUND_IMAGE_WIDTH = 1105;

/**
 * Height of compound images of svg type.
 *
 * @type {number}
 * @const
 */
bbConsts.SVG_COMPOUND_IMAGE_HEIGHT = 184;

/**
 * Width of compound images of svg type.
 *
 * @type {number}
 * @const
 */
bbConsts.SVG_COMPOUND_IMAGE_WIDTH = 226;

/**
 * Width of an image in the Dropdown component
 *
 * @type {number}
 * @const
 */
bbConsts.DROPDOWN_IMAGE_WIDTH = 200;

/**
 * Height of an image in the Dropdown component
 *
 * @type {number}
 * @const
 */
bbConsts.DROPDOWN_IMAGE_HEIGHT = 130;

/**
 * Height of row rendering image in the Dropdown component
 * Equals (bbConsts.DROPDOWN_IMAGE_HEIGHT + some margin)
 *
 * @type {number}
 * @const
 */
bbConsts.DROPDOWN_IMAGE_ROW_HEIGHT = 135;

/**
 * Height of row rendering text in the Dropdown component
 *
 * @type {number}
 * @const
 */
bbConsts.DROPDOWN_TEXT_ROW_HEIGHT = 30;

/**
 * Max height of selected and unselected list in the Dropdown component
 * keep in sync with `max-height` property in css of the component
 *
 * @type {number}
 * @const
 */
bbConsts.DROPDOWN_MAX_LIST_HEIGHT = 200;

/**
 * Min height of selected and unselected list in the Dropdown component
 *
 * @type {number}
 * @const
 */
bbConsts.DROPDOWN_MIN_LIST_HEIGHT = 15;

/**
 * Width of the color picker palate
 * Used to adjust the palate's position so that it doesn't go off screen
 *
 * @type {number}
 * @const
 */
bbConsts.COLOR_PICKER_PALATE_WIDTH = 166;

/**
 * Height of the color picker palate
 * Used to adjust the palate's position so that it doesn't go off screen
 *
 * @type {number}
 * @const
 */
bbConsts.COLOR_PICKER_PALATE_HEIGHT = 139;

/**
 * Map of additional color Name i.e color name not recognized by web to their Hex
 *
 * @type {Object<string, string>}
 * @const
 */
bbConsts.EXTRA_COLOR_NAME_TO_HEX = {
  bluepurple: '#960cda',
};

bbConsts.BLACK_COLOR_HEX_CODE = '#000000';

/**
 * @type {number}
 * @const
 */
bbConsts.SIMPLE_TOOLTIP_MIN_WIDTH = 106;

/**
 * @type {number}
 * @const
 */
bbConsts.SIMPLE_TOOLTIP_MAX_HEIGHT = 45;

/**
 * @type {number}
 * @const
 */
bbConsts.BYTES_PER_KILOBYTE = 1024;
export default bbConsts;
